import consumer from "./consumer"

const sellsChannel = consumer.subscriptions.create("SellsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('message received:' + data);
    let my_id = parseInt($('#login-staff').data('id'));
    $('.sell-inquiry-table .staff-sell_inquiry-edit').addClass('icon-none');
    $('.sell-inquiry-table .editing-by').text('');
    $.each(data, function(index, obj){
      console.log(index, obj);
      let $tr = $('.sell-inquiry-table tr[data-id="' + obj.target_id + '"]');
      let $icon = $tr.find('.staff-sell_inquiry-edit').removeClass('icon-none');
      $tr.find('.char-icon').removeClass('force-red');
      $tr.find('.editing-by').text(obj.name);
      if (obj.id == my_id) {
        $icon.addClass('staff-me');
      }
      else {
        $icon.removeClass('staff-me');
        $tr.find('.sell_inquiry-edit').hide();
      }
    });
    //const StaffShared = require('../../staff/shared.js.erb');
    //StaffShared.message_received(data);
  }

});

